import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import ColumnDetail from '@/views/ColumnDetail.vue'
import CreateArticle from '@/views/CreateArticle.vue'
import Registry from '@/views/Registry.vue'
import PostDetail from '@/views/PostDetail.vue'
import { useUserStore } from '@/store/user'
import createMessage from '@/components/createMessage'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      redirectAreadyLogin: true
    }
  },
  {
    path: '/registry',
    name: 'registry',
    component: Registry
  },
  {
    path: '/column/:id',
    name: 'column',
    component: ColumnDetail
  },
  {
    path: '/create/:id?',
    name: 'create',
    component: CreateArticle,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/post/:id?',
    name: 'post',
    component: PostDetail
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  const { requiredLogin, redirectAreadyLogin } = to.meta
  if (!userStore.isLogin) {
    if (userStore.token) {
      userStore.fetchCurrentUser().then(() => {
        if (redirectAreadyLogin) {
          next('/')
        } else {
          next()
        }
      }).catch((error) => {
        createMessage('登录失败，请重新登录', error, 2000)
        userStore.logout()
        next('/login')
      })
    } else {
      if (requiredLogin) {
        next('/login')
      } else {
        next()
      }
    }
  } else {
    if (redirectAreadyLogin) {
      next('/')
    } else {
      next()
    }
  }
})

export default router

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import { store, key } from './store'
import request from './utils/request'
import { createPinia } from 'pinia'
import { useGlobalStore } from './store/global'

// 全局配置

// pinia
const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.$axios = request
app.use(router)
app.use(store, key)
app.use(pinia)

const globalStore = useGlobalStore()
// loading状态
request.interceptors.request.use((config) => {
  // store.commit('setLoading', true)
  // store.commit('setError', { status: false, message: '' })
  globalStore.setLoading(true)
  globalStore.setError({ status: false, message: '' })
  return config
})
request.interceptors.response.use((config) => {
  setTimeout(() => {
    store.commit('setLoading', false)
    globalStore.loading = false
  }, 1000)
  return config
}, e => {
  const { error } = e.response?.data
  globalStore.setLoading(false)
  globalStore.setError({ status: true, message: error })
  // store.commit('setError', { status: true, message: error })
  // store.commit('setLoading', false)
  return Promise.reject(e)
})

app.mount('#app')

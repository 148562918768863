<template>
  <nav
    class="navbar bg-primary justify-content-between mb-1 p-2 h-auto"
    data-bs-theme="dark"
  >
    <router-link to="/" class="navbar-brand" href="#">乐子专栏</router-link>
    <ul v-if="!islogin" class="list-inline mb-0">
      <li class="list-inline-item">
        <router-link to="/login" class="btn btn-outline-light my-2">登录</router-link>
      </li>
      <li class="list-inline-item">
        <router-link to="/registry" class="btn btn-outline-light my-2">注册</router-link>
      </li>
    </ul>
    <ul v-else-if="islogin && data" class="list-inline mb-0">
      <li class="list-inline-item">
        <drop-down :title="`你好 ${data.nickName}`">
          <drop-down-item><router-link :to="`/create`">新建文章</router-link></drop-down-item>
          <drop-down-item><router-link :to="`/column/${data.column}`">我的专栏</router-link></drop-down-item>
          <drop-down-item :disabled="true"><a href="#">编辑资料</a></drop-down-item>
          <drop-down-item><a href="#" @click="handleLogout">退出登录</a></drop-down-item>
        </drop-down>
    </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { useRouter } from 'vue-router'
import DropDown from './DropDown.vue'
import DropDownItem from './DropDownItem.vue'
import { useUserStore, UserDataProps } from '@/store/user'
import createMessage from './createMessage'
export default defineComponent({
  name: 'GlobalHeader',
  props: {
    data: {
      type: Object as PropType<UserDataProps | null>,
      required: true
    },
    islogin: {
      type: Boolean,
      required: true
    }
  },
  components: {
    DropDown,
    DropDownItem
  },
  setup () {
    const userStore = useUserStore()
    const router = useRouter()
    const handleLogout = () => {
      userStore.logout()
      createMessage('退出登录成功，2秒后跳转到首页', 'success', 2000)
      setTimeout(() => {
        router.push('/')
      }, 2000)
    }
    return {
      handleLogout
    }
  }
})
</script>

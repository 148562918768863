<template>
  <div class="container text-center d-flex flex-column align-items-center" style="width: 80%">
    <div class="row justify-content-start h-25 border-bottom mb-3 p-3 w-100" v-if="columnDetails">
      <div class="col-3 align-self-center justify-content-center">
        <img :src="columnDetails.avatar && columnDetails.avatar.url"
          :alt="columnDetails.title"
          class="rounded-circle border border-primary w-100"
        />
      </div>
      <div class="col-9 text-start">
        <h3>{{ columnDetails.title }}</h3>
        <p class="text-break">{{ columnDetails?.description }}</p>
      </div>
    </div>
    <post-list :list="list"></post-list>
  </div>
  <div class="loadmore-btn text-center mt-0">
    <button
      class="btn btn-outline-primary mt-2 mb-2 mx-auto btn-block w-25"
      @click="loadMorePage"
      v-if="!isLastPage"
    >
      加载更多
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import PostList from '@/components/PostList.vue'
import { useStore, ColumnProps } from '@/store'
import { useColumnStore } from '@/store/column'
import { useUserStore } from '@/store/user'
import { addColumnAvatar } from '@/utils/helper'
import useLoadMore from '@/hooks/useLoadMore'
export default defineComponent({
  name: 'ColumnDetail',
  components: {
    PostList
  },
  setup () {
    const route = useRoute()
    const columnStore = useColumnStore()
    const userStore = useStore()
    const currentColumnId = ref(route.params.id as string)
    const store = useStore()
    const total = computed(() => store.getters.getPostTotal(currentColumnId))
    const currentPage = computed(() => store.getters.getPostCurrentPage(currentColumnId))
    onMounted(() => {
      // store.dispatch('fetchColumn', currentColumnId)
      columnStore.getColumnById(currentColumnId.value)
      store.dispatch('fetchPosts', { cid: currentColumnId.value as string, pageSize: 5 })
    })
    const columnDetails = computed(() => {
      const selectColumn = columnStore.getColumnById(currentColumnId.value)
      if (selectColumn) {
        addColumnAvatar(selectColumn, 100, 100)
      }
      return selectColumn
    })
    const list = computed(() => store.getters.getPostsByCid(currentColumnId.value))
    const { loadMorePage, isLastPage } = useLoadMore('fetchPosts', total, { cid: currentColumnId.value as string, currentPage: (currentPage.value ? currentPage.value + 1 : 2), pageSize: 5 })
    return {
      columnDetails,
      list,
      loadMorePage,
      isLastPage
    }
  }
})
</script>

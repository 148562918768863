<template>
  <div class="container m-auto min-vh-100">
    <global-header :data="user" :islogin="islogin"/>
    <loader v-if="isLoading" />
    <router-view></router-view>
  </div>
  <footer class="text-center py-4 text-secondary bg-light">
    <small>
      <ul class="list-inline mb-0">
        <li class="list-inline-item">© 2024 乐子人</li>
        <li class="list-inline-item">课程</li>
        <li class="list-inline-item">文档</li>
        <li class="list-inline-item">联系</li>
        <li class="list-inline-item">更多</li>
        <li class="list-inline-item">
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024326560号-1</a>
        </li>
        <li class="list-inline-item">
          <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002005023" rel="noreferrer" target="_blank">粤公网安备44030002005023</a>
        </li>
      </ul>
    </small>
  </footer>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import GlobalHeader from './components/GlobalHeader.vue'
import Loader from './components/Loader.vue'
import { useGlobalStore } from './store/global'
import { useUserStore } from './store/user'
import createMessage from './components/createMessage'
import 'easymde/dist/easymde.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
export default defineComponent({
  name: 'App',
  components: {
    GlobalHeader,
    Loader
  },
  setup () {
    const globalStore = useGlobalStore()
    const userStore = useUserStore()
    const user = computed(() => userStore.data)
    const islogin = computed(() => userStore.isLogin)
    const isLoading = computed(() => globalStore.loading)
    const error = computed(() => globalStore.error)
    console.log('error', error.value)
    watch(() => error.value.status, () => {
      const { status, message } = error.value
      if (status && message) {
        createMessage(message, 'error', 2000)
      }
    })
    return {
      isLoading,
      error,
      user,
      islogin
    }
  }
})
</script>

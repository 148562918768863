import { defineStore } from 'pinia'
import request from '@/utils/request'
import { ResponseType, ImageProps, ListDictProps, ListRespType, ListReqType } from './utils'
import { arrToObj, objToArr } from '@/utils/helper'

export interface ColumnProps {
    _id: string;
    title: string;
    avatar?: ImageProps;
    description: string;
    createAt?: string
}

interface GlobalColumnsProps {
  data: ListDictProps<ColumnProps>,
  currentPage: number,
  total: number
}

export const useColumnStore = defineStore('column', {
  state: (): GlobalColumnsProps => {
    return {
      data: {},
      currentPage: 0,
      total: 0
    }
  },
  actions: {
    async fetchColumns (params: ListReqType = {}) {
      const { currentPage = 1, pageSize = 6 } = params
      if (this.currentPage < currentPage) {
        const { data } = await request.get<ListRespType<ColumnProps>>('/columns', { params: { currentPage, pageSize } })
        const { list, count } = data.data
        this.$patch({
          currentPage: currentPage,
          total: count,
          data: { ...this.data, ...arrToObj(list) }
        })
      }
    },
    async fetchColumn (cid: string) {
      const cIdArr = Object.keys(this.data)
      if (!cIdArr.includes(cid)) {
        const { data: rowData } = await request.get<ResponseType<ColumnProps>>(`/columns/${cid}`)
        const { data } = rowData
        this.data[data._id] = data
      }
    }
  },
  getters: {
    getColumns: (state) => {
      return objToArr(state.data)
    },
    getColumnById: (state) => (id: string) => {
      return state.data[id]
    }
  }
})

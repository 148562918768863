import { defineStore } from 'pinia'
import { ImageProps, ResponseType } from './utils'
import request from '@/utils/request'

export interface UserDataProps {
  _id?: string;
  email?: string;
  nickName?: string;
  column?: string;
  description?: string;
  avatar?: ImageProps;
}

export interface UserProps {
  isLogin: boolean;
  token: string;
  data: UserDataProps | null;
}

export const useUserStore = defineStore('user', {
  state: (): UserProps => {
    return {
      data: null,
      isLogin: false,
      token: localStorage.getItem('token') || ''
    }
  },
  actions: {
    async login (email: string, password: string) {
      const payload = {
        email,
        password
      }

      const { data } = await request.post<ResponseType<UserProps>>('/user/login', payload)
      const { token } = data.data
      this.token = token
      localStorage.setItem('token', token)
    },
    logout () {
      localStorage.removeItem('token')
      this.isLogin = false
      this.token = ''
    },
    async fetchCurrentUser () {
      const { data } = await request.get<ResponseType>('/user/current')
      this.isLogin = true
      this.data = { ...(data.data as UserDataProps) }
    }
  }
})

import axios from 'axios'
const request = axios.create({
  baseURL: window.location.protocol + '//apis.imooc.com/api',
  timeout: 5000
})

const icode = '9252306B42637681'

request.interceptors.request.use(config => {
  // get 请求，添加到 url 中
  config.params = { ...config.params, icode: icode }
  // 如果是上传文件，添加到 FormData 中
  if (config.data instanceof FormData) {
    config.data.append('icode', icode)
  } else {
  // 普通的 body 对象，添加到 data 中
    config.data = { ...config.data, icode: icode }
  }
  const token = localStorage.getItem('token') || ''
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

// request.interceptors.response.use((res) => {
//   return res.data
// })

export default request

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container m-auto min-vh-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_header = _resolveComponent("global-header")!
  const _component_loader = _resolveComponent("loader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_global_header, {
        data: _ctx.user,
        islogin: _ctx.islogin
      }, null, 8, ["data", "islogin"]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ]),
    _cache[0] || (_cache[0] = _createStaticVNode("<footer class=\"text-center py-4 text-secondary bg-light\"><small><ul class=\"list-inline mb-0\"><li class=\"list-inline-item\">© 2024 乐子人</li><li class=\"list-inline-item\">课程</li><li class=\"list-inline-item\">文档</li><li class=\"list-inline-item\">联系</li><li class=\"list-inline-item\">更多</li><li class=\"list-inline-item\"><a href=\"https://beian.miit.gov.cn/\" target=\"_blank\">粤ICP备2024326560号-1</a></li><li class=\"list-inline-item\"><a href=\"https://beian.mps.gov.cn/#/query/webSearch?code=44030002005023\" rel=\"noreferrer\" target=\"_blank\">粤公网安备44030002005023</a></li></ul></small></footer>", 1))
  ], 64))
}